import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// core
import { InputLabel } from '@material-ui/core';
// Components
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import Snackbar from '../../components/Snackbar';
import OutlinedSelect from '../../components/OutlinedSelect';

import useStyles from './styles';
import authService from '../../services/authService';
import validateEmail from '../../utils/validator';

import {
  CLIENT, CLIENT_EMPLOYEE, OWNER, SCOUT, SUPER_ADMIN,
} from '../../utils/const/user';
import countries from './countries';

function Register({ update }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [inputValues, setInputValues] = useState({
    fullName: '',
    dni: '',
    countryCode: '',
    email: '',
    customPassword: '',
    role: '',
  });

  const handleChange = (e) => {
    const { id, name, value } = e.target;

    if (!id) {
      setInputValues({
        ...inputValues,
        [name]: value,
      });
    } else {
      setInputValues({
        ...inputValues,
        [id]: value,
      });
    }
  };

  const resetForm = () => {
    setInputValues({
      fullName: '',
      dni: '',
      countryCode: 'CL',
      email: '',
      customPassword: '',
      role: CLIENT,
    });

    update();
  };

  const handleSend = async () => {
    if (!inputValues.fullName) {
      setSeverity('error');
      setMessage(t('register.messages.invalidName'));
      return;
    }

    if (!inputValues.dni || inputValues.dni.length < 9) {
      setSeverity('error');
      setMessage(t('register.messages.invalidDNI'));
      return;
    }

    if (!inputValues.countryCode) {
      setSeverity('error');
      setMessage(t('register.messages.invalidCountry'));
      return;
    }

    if (!inputValues.email || !validateEmail(inputValues.email)) {
      setSeverity('error');
      setMessage(t('register.messages.invalidEmail'));
      return;
    }

    if (!inputValues.role) {
      setSeverity('error');
      setMessage(t('register.messages.invalidRole'));
      return;
    }

    if (inputValues.dni.length < 8 || inputValues.dni.length > 13) {
      setSeverity('error');
      setMessage(t('register.messages.invalidDNILength'));
      return;
    }

    if (inputValues.fullName.length < 3 || inputValues.fullName.length > 255) {
      setSeverity('error');
      setMessage(t('register.messages.invalidNameLength'));
      return;
    }

    const registerResponse = await authService.register(
      inputValues.fullName,
      inputValues.dni,
      inputValues.countryCode,
      inputValues.email,
      inputValues.customPassword,
      inputValues.role,
    );

    if (!registerResponse.success) {
      setSeverity('error');
      setMessage(registerResponse.errorMessage);
      return;
    }

    resetForm();
    setSeverity('success');
    setMessage(t('register.messages.success'));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div>
      <div className={styles.register}>
        <div>
          <InputLabel>{t('register.labels.name')}</InputLabel>
          <TextField
            variant="outlined"
            id="fullName"
            value={inputValues.fullName}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div>
          <InputLabel>{t('register.labels.dni')}</InputLabel>
          <TextField
            variant="outlined"
            id="dni"
            value={inputValues.dni}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div>
          <InputLabel>{t('register.labels.country')}</InputLabel>
          <OutlinedSelect
            name="countryCode"
            items={countries}
            value={inputValues.countryCode}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div>
          <InputLabel>{t('register.labels.email')}</InputLabel>
          <TextField
            variant="outlined"
            id="email"
            value={inputValues.email}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
       <div>
          <InputLabel>{t('register.labels.password')}</InputLabel>
          <TextField
            variant="outlined"
            id="customPassword"
            type="password"
            disabled
            value={inputValues.customPassword}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div className={styles.selectRole}>
          <InputLabel>{t('register.labels.role')}</InputLabel>
          <OutlinedSelect
            name="role"
            items={[{ value: CLIENT, name: CLIENT },
              { value: CLIENT_EMPLOYEE, name: CLIENT_EMPLOYEE },
              { value: OWNER, name: OWNER },
              { value: SCOUT, name: SCOUT },
              { value: SUPER_ADMIN, name: SUPER_ADMIN },
            ]}
            value={inputValues.role}
            onChange={handleChange}
            fullWidth
            InputProps={{
              onKeyDown: handleKeyDown,
            }}
          />
        </div>
        <div/>
        <Button
          id="submit-btn"
          onClick={handleSend}
          text={t('register.button')}
          height="35px"
          width="100%"
          backgroundColor="theme-secondary-blue"
          color="theme-primary-white"
          borderRadius="10px"
        />
      </div>
      <div>
        <Snackbar
          open={message !== ''}
          message={message}
          severity={severity}
          onClose={() => setMessage('')}
        />
      </div>
    </div>
  );
}

export default Register;
